import React from "react";
import { filteredEvents } from "../events";
import MyLink from "./MyLink";

interface Props {
  short?: boolean;
}

export default function Events({ short }: Props) {
  const events = filteredEvents();
  const theEvents = short ? events.slice(0, 3) : events;
  return (
    <>
      {theEvents.map((item, index) => (
        <MyLink
          id={item.title}
          className={`no-underline px-4 py-4 flex flex-col lg:flex lg:flex-row ${
            index % 2 == 0 ? "bg-white" : "bg-my-tan"
          }`}
          linkType="outbound_link"
          href={item.statusLink}
          eventName="event_clicked"
          details={{ event: item.analyticsTitle }}
        >
          <div className={short ? "w-full lg:w-1/6" : "w-full lg:w-1/3"}>
            <div className="font-medium text-my-orange">{item.dateFormat}</div>
          </div>
          <div className={short ? "lg:w-1/6" : "lg:w-1/3"}>
            <span className="font-bold uppercase text-gray-900">
              {item.location}
            </span>
          </div>
          <div
            className={short ? "lg:w-1/2 text-black" : "lg:w-1/2 text-black"}
          >
            <span>{item.title}</span>
            <br />
            <span className="text-gray-600">w/ {item.participants}</span>
            <br />
          </div>

          {/* <div className="lg:w-1/4">
            <MyLink
              href={item.statusLink ? item.statusLink : undefined}
              target="_blank"
              className={`font-bold text-gray-500 ${
                item.statusLink
                  ? "underline text-gray-800"
                  : item.status == "Sold out"
                  ? "line-through"
                  : ""
              }`}
            >
              {item.status}
            </MyLink>
          </div> */}
        </MyLink>
      ))}
    </>
  );
}
