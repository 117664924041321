/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/NavBar";
import Events from "../components/Events";
import MyLink from "../components/MyLink";
import Footer from "../components/Footer";

// @ts-ignore
import hero from "../images/hero_2_center.jpeg";
// @ts-ignore
import erin from "../images/erin_2.jpeg";
// @ts-ignore
import playbookLarge from "../images/playbook_3.png";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Example(props: PageProps) {
  return (
    <div className="bg-white">
      <SEO
        title="Erin Kelly | Yoga and Art"
        includeMainTitle={false}
        image={hero}
      />
      <NavBar {...props} />
      <main className="overflow-hidden">
        <img
          className="h-52 md:h-80 lg:h-96 w-full object-cover object-center"
          src={hero}
          alt="Erin Kelly Art"
        />
        {/* <div className="absolute inset-x-0 bottom-0 h-1/2 bg-my-tan" /> */}
        <div className="relative max-w-7xl mx-auto pt-6 md:pt-12 px-4 sm:px-6 lg:px-8">
          <h3 className="pb-2 lg:pb-6 text-3xl font-extrabold tracking-tight text-my-orange sm:text-4xl">
            Upcoming Workshops
          </h3>

          <Events short={true} />
          <div className="mt-4 sm:mt-8 gap-4 sm:flex sm:justify-center md:justify-start">
            <div className="rounded-md shadow">
              <MyLink
                href="/workshops"
                linkType="page_link"
                eventName="view_workshops_clicked"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-my-orange hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
              >
                {/* See If I'm Near You */}
                View Workshops
              </MyLink>
            </div>
            <div className="mt-4 sm:mt-0 rounded-md shadow">
              <MyLink
                href="https://anthony.difiglio.com/?utm_source=erin"
                eventName="meet_anthony_clicked"
                linkType="outbound_link"
                className="no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-my-orange bg-my-tan hover:bg-my-tan-700 md:py-4 md:text-lg md:px-10"
              >
                Meet Anthony
              </MyLink>
            </div>
          </div>
        </div>
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="mt-24 hidden lg:block bg-my-tan absolute top-0 bottom-0 right-3/4 w-screen" />
          <div className="mx-auto text-base z-10 max-w-prose lg:grid lg:grid-cols-1 lg:gap-8 lg:max-w-none">
            <h3 className="mt-2 text-3xl leading-8 contents lg:hidden font-extrabold tracking-tight text-my-orange sm:text-4xl">
              Authentic Movements App
            </h3>
          </div>
          <div className="mt-6 sm:mt-28 lg:grid lg:grid-cols-2 lg:gap-20">
            <div className="relative lg:row-start-1 lg:col-start-1">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <MyLink
                    href="https://authenticmovements.app/"
                    eventName="am_app_image_clicked"
                    linkType="outbound_link"
                    className="aspect-w-12 aspect-h-12 lg:aspect-none"
                  >
                    <img
                      className="rounded-lg shadow-lg object-cover object-top"
                      src={playbookLarge}
                      alt="Playbook"
                    />
                  </MyLink>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <h3 className="mt-8 text-3xl hidden lg:contents font-extrabold tracking-tight text-my-orange sm:text-4xl">
                Authentic Movements App
              </h3>
              <div className="mt-5 text-md lg:text-lg prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <div>
                  I offer creative vinyasa flows, playful transitions, and
                  accessible inversion exploration through:
                  <br />
                  <br />• Curated Weekly Guides
                  <br />• Structured Programs
                  <br />• Organized Categories
                  <br />• Comprehensive E-Books
                </div>
                <div className="mt-8 sm:mt-12 gap-4 sm:flex sm:justify-center md:justify-start">
                  <div className="rounded-md shadow">
                    <MyLink
                      href="https://my.playbookapp.io/authentic-movements/checkout?promo=authenticmovements1"
                      eventName="am_app_start_today_clicked"
                      linkType="outbound_link"
                      className="no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-my-orange hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Start Today for $1
                    </MyLink>
                  </div>
                  <div className="mt-4 sm:mt-0 rounded-md shadow">
                    <MyLink
                      href="https://authenticmovements.app/"
                      eventName="am_app_learn_more_clicked"
                      linkType="outbound_link"
                      className="no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-my-orange bg-my-tan hover:bg-my-tan-700 md:py-4 md:text-lg md:px-10"
                    >
                      Learn More
                    </MyLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="mt-24 hidden lg:block bg-my-tan absolute top-0 bottom-0 left-3/4 w-screen" />
          <div className="mx-auto text-base z-10 max-w-prose lg:grid lg:grid-cols-1 lg:gap-8 lg:max-w-none">
            <h3 className="mt-2 text-3xl leading-8 contents lg:hidden font-extrabold tracking-tight text-my-orange sm:text-4xl">
              About Erin
            </h3>
          </div>
          <div className="mt-6 sm:mt-28 lg:grid lg:grid-cols-2 lg:gap-20">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-12 lg:aspect-none">
                    <img
                      className="rounded-lg shadow-lg object-cover object-top"
                      src={erin}
                      alt="Picture of Erin"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <h3 className="mt-8 text-3xl hidden lg:contents font-extrabold tracking-tight text-my-orange sm:text-4xl">
                About Erin
              </h3>
              <div className="mt-5 text-md lg:text-lg prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p>
                  Erin{" "}
                  <MyLink
                    href="https://www.instagram.com/erinkellyart/"
                    eventName="home_about_instagram_clicked"
                    linkType="outbound_link"
                  >
                    @erinkellyart
                  </MyLink>{" "}
                  is passionate about the emotional, physical, and psychological
                  breakthroughs that can happen when we open our bodies up to
                  the experience of art and movement of all kinds. Whether it’s
                  with a paintbrush in hand or with her body, Erin is
                  continuously creating her own form of art and expression. A
                  true artist by nature, Erin has BFA in Oil Painting with a
                  minor in Art History and Psychology from The Maryland
                  Institute College of Art.
                </p>
                <MyLink
                  href="/about"
                  linkType="page_link"
                  eventName="more_about_erin_clicked"
                  className="underline "
                >
                  More about Erin...
                </MyLink>

                {/* <div className="rounded-md shadow">
                <MyLink
                  href="/about"
                  className="no-underline flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-my-orange bg-my-tan hover:bg-my-orange-50 md:py-4 md:text-lg md:px-10"
                >
                  More about Erin
                </MyLink>
              </div> */}
              </div>
              {/* <div className="mt-8 sm:mt-12 sm:flex sm:justify-center md:justify-start">
              <div className="rounded-md shadow">
                <MyLink
                  href="/about"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-my-orange bg-my-tan hover:bg-my-tan-700 md:py-4 md:text-lg md:px-10"
                >
                  More About Erin
                </MyLink>
              </div>
            </div> */}
            </div>
          </div>
        </div>

        {/* <div className="relative py-16 bg-my-tan overflow-hidden" id="about">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                About Erin
              </span>
            </h1>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>Lorem ipsum</p>
          </div>
        </div>
      </div> */}
        <Footer />
      </main>
    </div>
  );
}
