interface Event {
  location: string;
  title: string;
  dateFormat: string;
  dateEnd: Date;
  status: string;
  statusLink?: string;
  participants: string;
  analyticsTitle: string;
}

export const events: Event[] = [
  // {
  //   location: "Dallas, TX",
  //   title: "Wristoring Your Practice",
  //   dateFormat: "Sept 18th",
  //   dateEnd: new Date("2022-09-18T10:00:00"),
  //   participants: "Erin & Anthony",
  //   status: "Sign Up",
  //   statusLink: "https://www.secondsideyoga.com/workshop/erin",
  //   analyticsTitle: "dallas_2022_09",
  // },
  // {
  //   location: "Taos, NM",
  //   title: "Cultivating Collective Creativity Retreat",
  //   dateFormat: "Sept 23rd - 30th",
  //   dateEnd: new Date("2022-09-30T10:00:00"),
  //   participants: "Erin, Stevi, Bianca & Anthony",
  //   status: "Sign Up",
  //   statusLink: "https://authenticmovements.com/ccc-taos/",
  //   analyticsTitle: "taos_2022_09",
  // },
  // {
  //   location: "Orem, UT",
  //   title: "Wristoring Your Practice",
  //   dateFormat: "Oct 14th - 16th",
  //   dateEnd: new Date("2022-10-16T10:00:00"),
  //   participants: "Erin & Anthony",
  //   status: "Sign Up",
  //   statusLink:
  //     "https://clients.mindbodyonline.com/classic/ws?studioid=274613&stype=-8&sTG=24&sVT=10&sView=day&sLoc=1&sTrn=100000221&date=10/14/22",
  //   analyticsTitle: "orem_2022_10",
  // },
  {
    location: "Charleston, SC",
    title: "Practicing & Teaching Inversions - 50HR",
    dateFormat: "Nov 14th - 18th",
    dateEnd: new Date("2022-11-18T10:00:00"),
    participants: "Erin, Stevi & Bianca",
    status: "Sign Up",
    statusLink: "https://authenticmovements.com/50hr-inversion-charleston/",
    analyticsTitle: "charleston_2022_11",
  },
];

export function filteredEvents() {
  return events.filter(
    (event) => event.dateEnd.getTime() > new Date().getTime()
  );
}
